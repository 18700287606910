<template>
  <div class="title-text-documents animated fadeIn">
    <app-section class="bg-white">
      <div class="information w-full">
        <h1 class="title pt-32 text-46 font-semibold">{{ page.title }}</h1>
        <p class="description pt-32 pb-82 text-20" v-html="sanitizedText" />
      </div>
    </app-section>
    <app-section class="documents bg-grey-100 pt-104 pb-32">
      <documents-and-links-wrapper
        :documents="page.documents"
        :links="page.links"
        :document-link-text="$t('SHARED.MORE_INFORMATION')"
      />
    </app-section>
  </div>
</template>

<script>
import BaseTemplate from '@/mixins/BaseTemplate'
import DOMPurify from 'dompurify'

export default {
  name: 'TitleTextDocuments',
  components: {
    AppSection: () => import('@/components/AppSection.vue'),
    DocumentsAndLinksWrapper: () => import('@/components/DocumentsAndLinksWrapper.vue'),
  },
  mixins: [BaseTemplate],
  computed: {
    sanitizedText() {
      if (!this.page.text) return ''
      return DOMPurify.sanitize(this.page.text)
    },
  },
}
</script>
